var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Dutch = {
    weekdays: {
      shorthand: ["zo", "ma", "di", "wo", "do", "vr", "za"],
      longhand: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"]
    },
    months: {
      shorthand: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sept", "okt", "nov", "dec"],
      longhand: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
    },
    firstDayOfWeek: 1,
    weekAbbreviation: "wk",
    rangeSeparator: " t/m ",
    scrollTitle: "Scroll voor volgende / vorige",
    toggleTitle: "Klik om te wisselen",
    time_24hr: true,
    ordinal: function (nth) {
      if (nth === 1 || nth === 8 || nth >= 20) return "ste";
      return "de";
    }
  };
  fp.l10ns.nl = Dutch;
  var nl = fp.l10ns;
  exports.Dutch = Dutch;
  exports.default = nl;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Dutch = exports.Dutch,
      __esModule = exports.__esModule;